import React from "react";
import Dialog from '@/components/dialog/Dialog';
import apis from '@/api/api';
import EmployeeForm from './EmployeeForm';
import { hideLoading, showLoading } from "@/components/message/message";
import WebApi from "@/web/api";

interface CreateEmployeeProps {
  onOk(): void
}

export default class CreateEmployee extends React.PureComponent<CreateEmployeeProps>{

  private form: EmployeeForm | null = null

  state: {
    visible: boolean
    roleList: IRoleType[]
  } = {
    visible: false,
    roleList: []
  }

  componentDidMount() {
    this.findRoleAll();
  }

  private findRoleAll = () => {
    WebApi.auth_role_list({})
    .then(data => {
      this.setState({
        roleList: data,
      });
    })
  }

  render() {
    return <Dialog
      title="新增员工"
      visible={this.state.visible}
      onOk={this.clickSave}
      full={false}
      onCancel={() => this.setState({visible: false})}
    >
      <div style={{width: 500}}>
        <EmployeeForm mode="create" ref={r => this.form = r}></EmployeeForm>
      </div>
    </Dialog>
  }

  private clickSave = () => {
    this.form?.validateFields()
    .then(data => {
      showLoading(0);
      WebApi.employee_create(data)
      .then(() => {
        this.setState({
          visible: false,
        });
        showSuccess('保存成功');
        this.props.onOk();
      })
      .catch(e => {
        showError('保存出错: ' + e.message)
      })
      .finally(() => {
        hideLoading();
      })
    })
    .catch(e => {
      console.error(e);
    })
  }
}