import { Dialog, ViewTable, SearchTree, Status, Button, Layout } from "@/components";
import { Form, Input } from "antd";
import React from "react";
import CreateEmployee from "./components/CreateEmployee";
import UpdateEmployee from "./components/UpdateEmployee";
import globalCompanyStore from '@/global_store/globalCompanyStore';
import { FormInstance } from "antd/lib/form";
import MD5 from "lib/md5";
import { observer } from "mobx-react";
import WebApi from "@/web/api";
import { LibAuthCode } from "lib/Auth";
const theme = require('../../../config/theme');

@observer
export default class CompanyPage extends React.PureComponent<RouterPropsType> {

  private table: ViewTable | null = null
  private createEmployee: CreateEmployee | null = null
  private updateEmployee: UpdateEmployee | null = null
  private resetForm: FormInstance | null = null
  private updateDeptForm: FormInstance | null = null
  private createDeptForm: FormInstance | null = null

  state: {
    departmentName: string
    departmentId: string
    employeeId: string
    fullName: string
    resetVisible: boolean
    updateDeptVisible: boolean
    createDeptVisible: boolean
  } = {
    departmentName: '',
    departmentId: 'all',
    employeeId: '',
    fullName: '',
    resetVisible: false,
    updateDeptVisible: false,
    createDeptVisible: false
  }
  componentDidMount() {
    globalCompanyStore.findDeptAll();
  }

  render() {
    return <Layout.Page>
      <Layout.Toolbar title="部门与员工">
        <Button type="primary" authCode={LibAuthCode.employee.create} onClick={() => {
          this.createEmployee?.setState({
            visible: true
          });
        }}>新增员工</Button>
      </Layout.Toolbar>
      <Layout flex1>
        <div style={{display: 'flex', height: '100%'}}>
          <SearchTree title="部门"
            key={globalCompanyStore.departmentList.length}
            buttons={[
              <Button.Create authCode={LibAuthCode.department.create} onClick={() => {
                this.setState({createDeptVisible: true});
              }}>新增</Button.Create>,
              <Button.Edit authCode={LibAuthCode.department.update} disabled={this.state.departmentId === 'all'} onClick={() => {
                this.setState({updateDeptVisible: true});
              }}>编辑</Button.Edit>,
              <Button.Delete authCode={LibAuthCode.department.delete} disabled={this.state.departmentId === 'all'} onClick={this.clickDeleteDept}>删除</Button.Delete>
            ]}
            showIcon={true}
            treeData={[
              {
                title: '全部部门',
                key: 'all',
                children: globalCompanyStore.departmentList.map(i => {
                  return {
                    title: i.departmentName,
                    key: i.departmentId,
                  };
                })
              }
            ]}
            onSelect={(keys) => {
              const departmentName = keys[0] === 'all' ? '全部部门' : globalCompanyStore.departmentList.find(d => d.departmentId === keys[0])?.departmentName;
              this.setState({
                departmentId: keys[0],
                departmentName,
              });
              this.table?.reset({
                departmentId: keys[0] === 'all' ? '' : keys[0]
              });
            }}
          />
          <div style={{flex: 1, paddingLeft: 15, height: '100%'}}>
            <ViewTable
              height
              ref={r => this.table = r}
              rowKey={(row: any) => row.id}
              fetch={(data: any) => WebApi.employee_list({...data, withRole: true})}
              columns={[
                {
                  title: '姓名',
                  width: 120,
                  dataIndex: 'fullName',
                },
                {
                  title: '部门',
                  width: 150,
                  dataIndex: 'departmentName',
                  render: value => value || '--'
                },
                {
                  title: '手机',
                  width: 150,
                  align: 'center',
                  dataIndex: 'mobile',
                },
                {
                  title: '状态',
                  width: 80,
                  dataIndex: 'statusDesc',
                  align: 'center',
                  render: (text, record) => {
                    if (record.status === '0') {
                      return <Status.Stop/>;
                    }
                    return <Status.Using/>
                  },
                },
                {
                  title: '是否授权',
                  width: 100,
                  align: 'center',
                  render: (value, row) => {
                    if (!Array.isArray(row.roleList) || row.roleList.length === 0) {
                      return <span style={{color: '#bbb', borderBottom: '1px solid #bbb', lineHeight: 1}}>
                        <i className="iconfont icon-dot"/>
                        未授权
                      </span>
                    }
                    return <span style={{color: theme['default-button-color']}}>
                      <i className="iconfont icon-auth"/>
                      已授权
                    </span>;
                  }
                },
                {
                  title: '创建时间',
                  width: 200,
                  align: 'center',
                  dataIndex: 'createdAt'
                },
              ]}
              pagination={false}
              actionButtons={[
                {
                  type: 'update',
                  authCode: LibAuthCode.employee.update,
                  onClick: row => {
                    this.updateEmployee?.setState({
                      visible: true,
                      ...row,
                      roleList: row.roleList,
                    });
                  }
                },
                {
                  type: 'setting',
                  authCode: LibAuthCode.employee.update,
                  onClick: row => {
                    this.setState({
                      resetVisible: true,
                      employeeId: row.id,
                      fullName: row.fullName
                    });
                  }
                },
                {
                  type: row => row.status === '0' ? 'enable' : 'disable',
                  authCode: LibAuthCode.employee.update,
                  onClick: row => {
                    this.clickDisable(row);
                  }
                },
              ]}
            />
            
            <CreateEmployee ref={r => this.createEmployee = r} onOk={() => {
              this.table?.refresh();
            }}/>

            <UpdateEmployee ref={r => this.updateEmployee = r} onOk={() => {
              this.table?.refresh({}, false);
            }}/>

            <Dialog title="重置密码" visible={this.state.resetVisible}
              onCancel={() => this.setState({resetVisible: false})}
              full={false}
              onOk={this.clickReset}>
              <Form labelCol={{span: 5}} ref={r => this.resetForm = r}>
              <Form.Item label="员工">
                  <div>{this.state.fullName}</div>
                </Form.Item>
                <Form.Item label="新密码" name="password" rules={[
                  {
                    required: true,
                    message: '请填写新密码'
                  },{
                    min: 6,
                    message: '密码必须6-20位字符'
                  },
                  {
                    max: 20,
                    message: '密码必须6-20位字符'
                  }
                ]}>
                  <Input.Password/>
                </Form.Item>
                <Form.Item label="确认密码" name="confirm" rules={[
                  {
                    required: true,
                    message: '请确认密码!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('两次密码填写不一致!'));
                    },
                  }),
                ]}>
                  <Input.Password/>
                </Form.Item>
              </Form>
            </Dialog>

            <Dialog visible={this.state.updateDeptVisible}
              title="编辑部门"
              full={false}
              onOk={this.clickUpdateDept}
              onCancel={() => this.setState({updateDeptVisible: false})}>
              <Form style={{padding: '30PX 0'}} ref={r => this.updateDeptForm = r} initialValues={{
                departmentName: this.state.departmentName,
              }}>
                <Form.Item label="部门" name="departmentName" rules={[{
                  required: true,
                  message: '部门名称不能为空'
                }]}>
                  <Input placeholder="填写部门名称" style={{width: '100%'}}/>
                </Form.Item>
              </Form>
            </Dialog>

            {/* 新增部门 */}
            <Dialog visible={this.state.createDeptVisible}
              title="新增部门"
              full={false}
              okText="保存"
              onOk={this.clickCreateDept}
              onCancel={() => this.setState({createDeptVisible: false})}
            >
              <Form style={{padding: '30PX 0'}} ref={r => this.createDeptForm = r}>
                <Form.Item label="部门" name="departmentName" rules={[{
                  required: true,
                  message: '部门名称不能为空'
                }]}>
                  <Input placeholder="填写部门名称" style={{width: '100%'}}/>
                </Form.Item>
              </Form>
            </Dialog>
          </div>
        </div>
      </Layout>
    </Layout.Page>
  }

  // 删除员工
  clickDelete = (row) => {
    Dialog.confirm({
      content: `确定删除员工“${row.fullName}”吗？`,
      onOk: () => {
        WebApi.employee_delete({
          employeeId: row.employeeId
        })
        .then(() => {
          showSuccess('删除成功');
          this.table?.reset();
        })
      }
    });
  }

  // 启用/禁用员工
  clickDisable = (row) => {
    const status = row.status === '1' ? '0' : '1';
    Dialog.confirm({
      content: `确定 ${status === '0' ? '“禁用”' : '“启用”'} “${row.fullName}” 吗？`,
      onOk: () => {
        WebApi.employee_update({
          employeeId: row.employeeId,
          status,
        })
        .then(() => {
          showSuccess.save();
          this.table?.refresh();
        })
      }
    })
  }

  // 重置密码
  clickReset = () => {
    this.resetForm?.validateFields()
    .then(data => {
      WebApi.employee_update({
        employeeId: this.state.employeeId,
        password: MD5.hex_md5(data.password)
      })
      .then(() => {
        showSuccess('重置成功');
        this.setState({
          resetVisible: false,
        });
      })
    })
    .catch(e => {
      console.error(e => console.error(e));
    });
  }

  // 修改部门
  clickUpdateDept = () => {
    this.updateDeptForm?.validateFields()
    .then(data => {
      WebApi.department_update({
        departmentId: this.state.departmentId,
        departmentName: data.departmentName,
      })
      .then(() => {
        showSuccess('保存成功');
        this.setState({
          updateDeptVisible: false,
        });
        globalCompanyStore.forceFindDeptAll();
      })
    })
  }

  // 删除部门
  clickDeleteDept = () => {
    Dialog.confirm({
      content: `确定删除 “${this.state.departmentName}” 吗？`,
      onOk: () => {
        WebApi.department_delete({departmentId: this.state.departmentId})
        .then(() => {
          showSuccess('删除成功');
          globalCompanyStore.forceFindDeptAll();
          this.table?.reset();
        });
      }
    });
  }

  // 新增部门
  clickCreateDept = () => {
    this.createDeptForm?.validateFields()
    .then(data => {
      WebApi.department_create({
        departmentName: data.departmentName,
      })
      .then(() => {
        showSuccess('保存成功');
        this.setState({
          createDeptVisible: false,
        });
        globalCompanyStore.forceFindDeptAll();
      })
    })
  }
}