import WebApi from '@/web/api';
import { LibIDepartment, LibIEmployee } from 'lib/interface/CompanyInterface';
import {action, makeObservable, observable, runInAction} from 'mobx'

class GlobalCompanyStore {
  @observable
  departmentList: LibIDepartment[] = []

  @observable
  deptLoaded: boolean = false

  @observable
  employeeList: LibIEmployee[] = []

  @observable
  employeeLoading: boolean = false

  constructor() {
    makeObservable(this);
  }

  @action
  findDeptAll() {
    if (this.deptLoaded) return;
    WebApi.department_list()
    .then((data) => {
      runInAction(() => {
        this.departmentList = data as LibIDepartment[];
        this.deptLoaded = true;
      });
    })
    .catch(e => {
      showError('查询部门出错: ' + e.message);
    })
  }

  @action
  findEmployeeList() {
    this.employeeLoading = true;
    WebApi.employee_list({})
    .then((data) => {
      runInAction(() => {
        this.employeeList = data as LibIEmployee[];
      });
    })
    .finally(() => {
      this.employeeLoading = false;
    })
  }

  @action
  forceFindDeptAll() {
    this.deptLoaded = false;
    this.findDeptAll();
  }
}

export default new GlobalCompanyStore();