import React from "react";
import { Form, Input, Select, Checkbox} from 'antd';
import { FormInstance } from "rc-field-form/lib/interface";
import MD5 from "@/utils/md5";
import globalCompanyStore from '@/global_store/globalCompanyStore';
import { observer } from "mobx-react";
import WebApi from "@/web/api";

@observer
export default class CreateEmployee extends React.PureComponent<{
  mode: 'create' | 'update'
  initialValues?: Record<any, any>
}>{

  private form: FormInstance | null = null

  state: {
    visible: boolean
    roleList: IRoleType[]
  } = {
    visible: false,
    roleList: []
  }

  componentDidMount() {
    this.findRoleAll();
  }

  private findRoleAll = () => {
    WebApi.auth_role_list({})
    .then(data => {
      this.setState({
        roleList: data,
      });
    })
  }

  render() {
    const {initialValues = {}} = this.props;

    return <div style={{width: 400}}>
      <Form ref={r => this.form = r} labelCol={{span: 5}} initialValues={{
        ...initialValues,
        roleIdList: initialValues.type === '1' ? ['admin'] : initialValues.roleList?.map(item => item.roleId) || [],
      }}>
        <Form.Item label="员工姓名" name="fullName" rules={[{
          required: true,
          message: '请填写员工姓名'
        }]}>
          <Input/>
        </Form.Item>
        <Form.Item label="手机号码" name="mobile" rules={[{
          required: true,
          message: '请填写手机号码'
        }, {
          pattern: /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
          message: '手机号码格式不正确'
        }]}>
          <Input/>
        </Form.Item>
        <Form.Item label="所在部门" name="departmentId" rules={[{
          required: true,
          message: '请选择部门'
        }]}>
          <Select>
            {
              globalCompanyStore.departmentList.map((item: any) => {
                return <Select.Option value={item.departmentId} key={item.departmentId}>{item.departmentName}</Select.Option>
              })
            }
          </Select>
        </Form.Item>
        {
          this.props.mode === 'create' &&
          <>
            <Form.Item label="登录密码" name="password" rules={[{
              required: true,
              message: '请填写登录密码'
            }]}>
              <Input.Password/>
            </Form.Item>
            <Form.Item
              name="confirm"
              label="确认密码"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: '请确认密码!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('两次密码填写不一致!'));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </>
        }
        <Form.Item label="授权角色" name="roleIdList">
          <Checkbox.Group>
            {
              initialValues.type === '1' && <Checkbox value="admin" key="1" disabled>系统管理员</Checkbox>
            }
            {
              initialValues.type !== '1' && this.state.roleList.map(item => {
                return <Checkbox value={item.roleId} key={item.roleId}>{item.roleName}</Checkbox>
              })
            }
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </div>
  }

  public validateFields = async (): Promise<any> => {
    const data =  await (this.form as FormInstance).validateFields()
    if (data) {
      return {
        fullName: data.fullName,
        password: data.password && MD5.hex_md5(data.password),
        mobile: data.mobile,
        departmentId: data.departmentId,
        roleIdList: data.roleIdList
      };
    }
    return Promise.reject();
  }
}